'use client'
import Script from 'next/script'
import TagManager from 'react-gtm-module'
import {useEffect} from 'react'
import {hotjar} from 'react-hotjar'
import Image from 'next/image'

const TAGMANAGER_ARGS = {
  gtmId: 'GTM-TNP5ZGJ',
}

declare global {
  interface Window {
    faitracker: any
    dataLayer: any[]
    trackingFunctions?: {
      onLoad: (config: {appId: string}) => void
    }
    intercomSettings: any
    lintrk: any
  }
}

// Pure function for initializing Google Analytics
const initGoogleAnalytics = () => {
  window.dataLayer = window.dataLayer || []
  function gtag(...args: any[]) {
    window.dataLayer.push(args)
  }
  gtag('js', new Date())
  gtag('config', 'AW-11143907915')
}

const initApollo = () => {
  const n = Math.random().toString(36).substring(7)
  const o = document.createElement('script')
  o.src = `https://assets.apollo.io/micro/website-tracker/tracker.iife.js?nocache=${n}`
  o.async = true
  o.defer = true
  o.onload = function () {
    // Check if trackingFunctions exists before calling
    if (window.trackingFunctions && typeof window.trackingFunctions.onLoad === 'function') {
      window.trackingFunctions.onLoad({appId: '6751997836df7301b0cc25db'})
    } else {
      console.warn('Apollo tracking functions not initialized properly')
    }
  }
  document.head.appendChild(o)
}

export default function LayoutComponent({children}: {children: React.ReactNode}) {
  useEffect(() => {
    hotjar.initialize({id: 4949609, sv: 6})
    window.intercomSettings = {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'ti87d1s7',
    }
    TagManager.initialize(TAGMANAGER_ARGS)

    // Initialize Apollo
    if (typeof window !== 'undefined') {
      initApollo()
    }
  }, [])

  // factors.ai code
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.faitracker =
        window.faitracker ||
        (() => {
          const tracker: any = {}
          tracker.q = []
          const t = new CustomEvent('FAITRACKER_QUEUED_EVENT')

          tracker.init = (token: string, initParams: object, initCallback: Function) => {
            tracker.TOKEN = token
            tracker.INIT_PARAMS = initParams
            tracker.INIT_CALLBACK = initCallback
            window.dispatchEvent(new CustomEvent('FAITRACKER_INIT_EVENT'))
          }

          tracker.call = (...args: any[]) => {
            const e: {k: string; a: any[]} = {k: '', a: []}
            if (args.length >= 1) {
              e.k = args[0]
              for (let a = 1; a < args.length; a++) {
                e.a.push(args[a])
              }
            }
            tracker.q.push(e)
            window.dispatchEvent(t)
          }

          tracker.message = () => {
            window.addEventListener('message', (t: MessageEvent) => {
              if (t.data.origin === 'faitracker') {
                tracker.call('message', t.data.type, t.data.message)
              }
            })
          }

          tracker.message()
          tracker.init('9drdbhdq3ujjklowtr25r1hzynyv9sbo', {host: 'https://api.factors.ai'})
          return tracker
        })()
      ;(() => {
        const t = document.createElement('script')
        t.type = 'text/javascript'
        t.src = 'https://app.factors.ai/assets/factors.js'
        t.async = true
        const d = document.getElementsByTagName('script')[0]
        d.parentNode?.insertBefore(t, d)
      })()
    }
  }, [])

  return (
    <div className="layout">
      {/* <!-- Google Tag Manager --> */}
      <Script
        id="gtm-script"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
        (function(w,d,s,l,i){
          w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
          f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TNP5ZGJ');
        `,
        }}
      />
      {/* <!-- End Google Tag Manager --> */}

      {/* <!-- Hotjar Tracking Code for ZenAdmin --> */}
      <Script
        id="hotjar"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:4949609,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `,
        }}
      />

      {/* Google Analytics */}
      <Script
        id="ga-script"
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-11143907915"
        onLoad={initGoogleAnalytics}
      />

      {/* <!-- Linkedin Insight Tag --> */}
      <Script type="text/javascript" id="linkedin-script">
        {`
        _linkedin_partner_id = "5375913"; 
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; 
        window._linkedin_data_partner_ids.push(_linkedin_partner_id); 
      `}
      </Script>
      <Script type="text/javascript" id="snap.licdn.com">
        {`
        (function(l) { 
          if (!l){
            window.lintrk = function (a, b) { 
              window.lintrk.q.push([a, b]) 
            }; 
            window.lintrk.q=[]
          } 
          var s = document.getElementsByTagName("script")[0]; 
          var b = document.createElement("script"); 
          b.type = "text/javascript";
          b.async = true; 
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; 
          s.parentNode.insertBefore(b, s);
        })(window.lintrk); 
      `}
      </Script>

      {/* <!-- Microsoft clarity script --> */}
      {process.env.NODE_ENV === 'production' && (
        <Script
          id="microsoft-clarity"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "nesz3jtv6q");
      `,
          }}
        />
      )}

      {/* <!-- Pixel code script --> */}
      <Script
        id="vtag-ai-js"
        strategy="lazyOnload"
        src="https://r2.leadsy.ai/tag.js"
        data-pid="6vggDiV1d8OGX6fv"
        data-version="062024"
      />
      <noscript>
        <Image
          loading="lazy"
          height={1}
          width={1}
          style={{display: 'none'}}
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=5375913&fmt=gif"
        />
      </noscript>
      {/* <!-- End Linkedin Insight Tag --> */}

      {/* GTM noscript iframe */}
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-TNP5ZGJ"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>`,
        }}
      />

      {children}
    </div>
  )
}
